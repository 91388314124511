import React from "react";

interface LogoAttributes {
  className?: string;
}

const LogoIcon: React.FC<LogoAttributes> = ({
  className = "",
}: LogoAttributes) => {
  return (
    <svg className={`fill-current ${className}`} viewBox="0 0 312 338">
      <g>
        <title>Sunrise Stake Logo - A seedling growing in the sun</title>
        <path d="M265.813 71.7712C245.044 51.0026 219.44 36.8368 191.548 30.2983C191.969 32.5695 192.321 34.8349 192.584 37.0885C254.72 53.3967 300.554 109.937 300.554 177.189C300.554 216.162 285.159 251.536 260.123 277.567C256.529 281.483 252.712 285.188 248.709 288.683C207.359 319.11 166.079 300.501 162.374 266.205L162.221 249.417C170.604 249.095 187.011 246.595 200.457 233.15C219.891 213.716 230.931 175.093 230.931 175.093C230.931 175.093 192.309 186.133 172.875 205.567C167.876 210.566 164.393 215.975 161.97 221.226L161.174 132.666C168.66 124.565 181.503 107.045 181.503 82.1497C181.503 46.0855 155.911 0 155.911 0C155.911 0 130.319 46.0855 130.319 82.1497C130.319 106.957 143.068 124.442 150.573 132.579L150.145 172.453C147.5 165.119 143.133 157.187 135.962 150.017C114.368 128.423 71.4551 116.153 71.4551 116.153C71.4551 116.153 83.7243 159.066 105.318 180.66C121.457 196.799 141.429 198.748 149.864 198.783L149.156 265.005C146.288 300.057 104.54 319.45 62.7098 288.677C58.706 285.182 54.8894 281.477 51.2953 277.561C26.2593 251.524 10.8643 216.151 10.8643 177.183C10.8643 109.785 56.8972 53.145 119.238 36.9715C119.508 34.7295 119.859 32.4642 120.292 30.1871C92.2296 36.6846 66.4796 50.8914 45.6056 71.7595C16.197 101.168 0 140.27 0 181.86C0 223.45 16.197 262.552 45.6056 291.961C75.0141 321.37 114.116 337.567 155.706 337.567C197.296 337.567 236.398 321.37 265.807 291.961C295.216 262.552 311.413 223.45 311.413 181.86C311.413 140.27 295.216 101.168 265.807 71.7595L265.813 71.7712Z" />
      </g>
    </svg>
  );
};

const LogoText: React.FC<LogoAttributes> = ({
  className = "",
}: LogoAttributes) => {
  return (
    <svg className={`fill-current ${className}`} viewBox="0 0 380 209">
      <g>
        <title>Sunrise Stake (Logo Text)</title>
        <path d="M59.4389 54.8373C58.4087 52.0686 56.7579 49.7095 54.5218 47.8364C52.315 45.9866 49.4819 44.4179 46.0927 43.1886C42.7444 41.9711 38.6703 40.8296 34.0108 39.7935C29.1172 38.5935 25.1074 37.4579 22.1045 36.4277C19.207 35.4326 17.1172 34.0628 15.8938 32.3536C14.6763 30.6443 14.0558 28.0921 14.0558 24.7673C14.0558 21.8698 14.6997 19.4932 15.9641 17.6961C17.2284 15.899 19.1485 14.5293 21.6597 13.622C24.2294 12.6971 27.5074 12.2288 31.4001 12.2288C33.2791 12.2288 35.802 12.3576 38.8869 12.6152C42.0068 12.8727 45.2263 13.1596 48.5629 13.4581C51.8702 13.7625 54.8087 14.0844 57.3082 14.4298L57.9169 14.5117L58.9296 3.10304L58.4028 3.00938C55.997 2.58207 53.076 2.14304 49.7336 1.70402C46.4204 1.27671 43.0019 0.902083 39.5717 0.597695C36.1415 0.293307 33.0391 0.141113 30.3581 0.141113C24.3406 0.141113 19.0021 0.984034 14.4948 2.64646C9.93483 4.3323 6.3817 7.07179 3.94074 10.8005C1.50563 14.5176 0.270508 19.5224 0.270508 25.6746C0.270508 29.9712 0.832471 33.6472 1.93295 36.5975C3.04514 39.5828 4.83635 42.094 7.24219 44.0608C9.62461 46.0042 12.6568 47.6315 16.2509 48.9018C19.8157 50.1603 24.0362 51.2842 28.8128 52.2383C33.4313 53.093 37.1542 54.0705 39.8819 55.13C42.5278 56.1661 44.4244 57.53 45.519 59.1924C46.6195 60.8666 47.1756 63.1846 47.1756 66.0821C47.1756 69.688 46.5434 72.6557 45.3024 74.8918C44.0731 77.1103 42.1941 78.7962 39.7239 79.9025C37.2127 81.0323 33.9698 81.6059 30.0888 81.6059C27.7708 81.6059 24.9435 81.4771 21.6889 81.2196C18.4167 80.962 15.0333 80.6342 11.6382 80.2479C8.21386 79.8557 5.21682 79.5279 2.72904 79.2703L2.14367 79.2118L0.984668 90.4976L1.54659 90.5737C4.16901 90.9249 7.22462 91.3581 10.6373 91.8732C14.0792 92.3883 17.6499 92.8273 21.2558 93.1785C24.8674 93.5239 28.0986 93.6995 30.8615 93.6995C36.9727 93.6995 42.3405 92.7219 46.8185 90.7902C51.3316 88.8468 54.8555 85.7444 57.2906 81.5825C59.714 77.4382 60.9433 71.9182 60.9433 65.1748C60.9433 61.0597 60.4282 57.5768 59.4096 54.8373H59.4389Z" />
        <path d="M111.776 26.1252V77.801C110.032 78.6088 107.79 79.4634 105.097 80.3473C102.27 81.278 99.1206 81.7463 95.7314 81.7463C92.3422 81.7463 89.7607 81.0907 88.0983 79.7912C86.4534 78.5093 85.3646 76.3142 84.8729 73.2703C84.3578 70.1152 84.1003 65.8186 84.1003 60.4977V26.1252H70.8418V60.6265C70.8418 68.1776 71.4155 74.4293 72.5452 79.2117C73.6984 84.0878 75.8466 87.7639 78.9315 90.1346C82.0105 92.4994 86.4827 93.6994 92.2192 93.6994C96.5919 93.6994 100.438 93.1199 103.657 91.9785C106.502 90.9658 109.23 89.7599 111.776 88.3902V92.4058H124.9V26.1311H111.776V26.1252Z" />
        <path d="M189.998 42.5973C189.295 38.4646 188.084 35.0461 186.404 32.4354C184.694 29.7896 182.312 27.8344 179.309 26.6227C176.347 25.4286 172.536 24.8257 167.976 24.8257C165.682 24.8257 163.27 25.1593 160.806 25.8208C158.377 26.4706 156.035 27.2432 153.852 28.1154C152.101 28.8179 150.585 29.5262 149.327 30.2344V26.1252H136.068V92.3999H149.327V40.8471C150.462 40.2968 151.885 39.7056 153.559 39.0792C155.385 38.4002 157.364 37.8441 159.448 37.4168C161.526 36.9954 163.563 36.7788 165.5 36.7788C168.989 36.7788 171.617 37.4578 173.327 38.7983C175.024 40.1271 176.172 42.3514 176.751 45.4012C177.348 48.568 177.652 52.8587 177.652 58.1562V92.3999H191.04V57.5064C191.04 51.7055 190.688 46.689 189.992 42.5914L189.998 42.5973Z" />
        <path d="M236.488 24.703C233.602 25.2298 230.658 26.0903 227.731 27.2727C224.822 28.4435 222.111 29.702 219.682 31.0015C217.704 32.061 215.989 33.0795 214.566 34.0337V26.1196H201.313V92.3943H214.566V45.8931C216.065 45.0795 217.973 44.1604 220.262 43.1595C222.697 42.0882 225.389 41.0697 228.264 40.1273C231.132 39.1849 233.971 38.4473 236.699 37.938L237.179 37.8502V24.5801L236.488 24.703Z" />
        <path d="M257.321 26.1252H244.062V92.3999H257.321V26.1252Z" />
        <path d="M250.695 0.392822H250.689C247.029 0.392822 244.062 3.35952 244.062 7.01912V7.54009C244.062 11.1997 247.029 14.1664 250.689 14.1664H250.695C254.354 14.1664 257.321 11.1997 257.321 7.54009V7.01912C257.321 3.35952 254.354 0.392822 250.695 0.392822Z" />
        <path d="M314.154 62.1425C312.62 59.6196 310.162 57.6586 306.854 56.3182C303.623 55.007 299.251 53.9065 293.859 53.0343C289.58 52.3494 286.238 51.6353 283.937 50.9094C281.731 50.2187 280.256 49.3875 279.553 48.4509C278.857 47.5202 278.5 46.2382 278.5 44.646C278.5 42.4626 278.997 40.7768 279.98 39.6354C280.976 38.4822 282.404 37.6685 284.224 37.2237C286.127 36.7612 288.287 36.5271 290.646 36.5271C292.695 36.5271 295.13 36.6149 297.887 36.7846C300.65 36.9603 303.442 37.2002 306.175 37.4988C308.921 37.8032 311.362 38.1251 313.422 38.4705L314.078 38.5817L314.517 27.5301L314.013 27.4423C312.017 27.0969 309.606 26.7047 306.854 26.2715C304.068 25.8384 301.147 25.4871 298.174 25.2296C295.182 24.972 292.349 24.8374 289.738 24.8374C285.623 24.8374 281.649 25.4579 277.926 26.6871C274.15 27.9281 271.042 30.0296 268.689 32.9154C266.318 35.8246 265.112 39.9046 265.112 45.0441C265.112 49.4694 265.932 52.9875 267.547 55.4987C269.169 58.0216 271.674 59.9181 274.987 61.124C278.213 62.3006 282.316 63.2957 287.186 64.0742C291.641 64.7591 295.065 65.4791 297.366 66.1991C299.584 66.8957 301.106 67.7503 301.891 68.7337C302.669 69.7112 303.061 71.1395 303.061 72.9834C303.061 75.3483 302.534 77.2156 301.498 78.5326C300.451 79.8556 298.894 80.8156 296.862 81.3834C294.761 81.9746 292.056 82.2731 288.831 82.2731C287.286 82.2731 285.184 82.1619 282.574 81.9512C279.963 81.7404 277.241 81.4536 274.478 81.1083C271.703 80.7629 269.233 80.4585 267.067 80.2009L266.447 80.1248L265.867 90.995L266.359 91.1004C268.425 91.5335 270.907 91.9491 273.741 92.3413C276.544 92.7277 279.442 93.0613 282.345 93.3189C285.249 93.5765 287.871 93.7111 290.131 93.7111C295.627 93.7111 300.369 93.0438 304.226 91.7326C308.142 90.3979 311.198 88.1326 313.293 85.0067C315.383 81.8868 316.449 77.6663 316.449 72.4624C316.449 68.1308 315.676 64.6654 314.154 62.1542V62.1425Z" />
        <path d="M379.99 55.8206C379.99 45.0617 377.642 37.1183 373.006 32.2247C368.364 27.3193 361.422 24.8315 352.372 24.8315C346.495 24.8315 341.367 26.0374 337.141 28.414C332.891 30.8022 329.549 34.6129 327.213 39.729C324.895 44.8041 323.719 51.5358 323.719 59.725C323.719 67.2118 324.603 73.5278 326.353 78.5034C328.121 83.5433 331.018 87.3833 334.969 89.9121C338.903 92.4291 344.318 93.7052 351.073 93.7052C353.824 93.7052 356.874 93.594 360.146 93.3774C363.395 93.1608 366.579 92.8506 369.605 92.4643C372.661 92.0721 375.289 91.6974 377.42 91.3579L377.929 91.276L377.636 81.0497L377.022 81.1024C374.985 81.2721 372.456 81.4477 369.5 81.6234C366.655 81.7931 363.664 81.9453 360.608 82.0799C357.635 82.2087 354.866 82.2731 352.384 82.2731C346.718 82.2731 342.772 80.7629 340.647 77.7775C338.546 74.8273 337.405 70.402 337.258 64.601H379.305L380.001 55.8265L379.99 55.8206ZM344.083 37.6217C346.325 36.3749 349.112 35.7485 352.378 35.7485C357.365 35.7485 361.036 37.1593 363.295 39.9514C365.519 42.7026 366.673 47.5787 366.737 54.4626H337.246C337.293 49.9787 337.89 46.3436 339.02 43.6451C340.173 40.8822 341.882 38.8568 344.089 37.6276L344.083 37.6217Z" />
        <path d="M59.4389 169.475C58.4087 166.706 56.7579 164.347 54.5218 162.474C52.315 160.624 49.4819 159.055 46.0927 157.826C42.7444 156.609 38.6703 155.467 34.0108 154.431C29.1172 153.231 25.1074 152.095 22.1045 151.065C19.207 150.07 17.1172 148.7 15.8938 146.991C14.6763 145.282 14.0558 142.73 14.0558 139.405C14.0558 136.507 14.6997 134.131 15.9641 132.334C17.2284 130.537 19.1485 129.167 21.6597 128.259C24.2294 127.335 27.5074 126.866 31.4001 126.866C33.2791 126.866 35.802 126.995 38.8869 127.253C42.0068 127.51 45.2263 127.797 48.5629 128.096C51.8702 128.4 54.8087 128.722 57.3082 129.067L57.9169 129.149L58.9296 117.74L58.4028 117.647C55.997 117.22 53.076 116.78 49.7336 116.341C46.4204 115.914 43.0019 115.54 39.5717 115.235C36.1415 114.931 33.0391 114.779 30.3581 114.779C24.3406 114.779 19.0021 115.621 14.4948 117.284C9.93483 118.97 6.3817 121.709 3.94074 125.438C1.50563 129.155 0.270508 134.16 0.270508 140.312C0.270508 144.609 0.832471 148.285 1.93295 151.235C3.04514 154.22 4.83635 156.731 7.24219 158.698C9.62461 160.642 12.6568 162.269 16.2509 163.539C19.8157 164.798 24.0362 165.922 28.8128 166.876C33.4313 167.73 37.1542 168.708 39.8819 169.767C42.5278 170.804 44.4244 172.167 45.519 173.83C46.6195 175.504 47.1756 177.822 47.1756 180.72C47.1756 184.325 46.5434 187.293 45.3024 189.529C44.0731 191.748 42.1941 193.434 39.7239 194.54C37.2127 195.67 33.9698 196.243 30.0888 196.243C27.7708 196.243 24.9435 196.115 21.6889 195.857C18.4167 195.599 15.0333 195.272 11.6382 194.885C8.21386 194.493 5.21682 194.165 2.72904 193.908L2.14367 193.849L0.984668 205.135L1.54659 205.211C4.16901 205.562 7.22462 205.996 10.6373 206.511C14.0792 207.026 17.6499 207.465 21.2558 207.816C24.8674 208.161 28.0986 208.337 30.8615 208.337C36.9727 208.337 42.3405 207.359 46.8185 205.428C51.3316 203.484 54.8555 200.382 57.2906 196.22C59.714 192.076 60.9433 186.556 60.9433 179.812C60.9433 175.697 60.4282 172.214 59.4096 169.475H59.4389Z" />
        <path d="M104.851 195.816L104.26 195.869C103.218 195.956 102 196.062 100.613 196.196C99.2371 196.325 97.8908 196.413 96.6206 196.454C95.3738 196.495 94.2908 196.518 93.3894 196.518C90.9367 196.518 89.1338 196.079 88.0333 195.213C86.9445 194.358 86.207 192.801 85.8441 190.577C85.4577 188.236 85.2645 184.922 85.2645 180.731V152.207H104.628V140.769H85.2645V121.668H72.1407V140.769H63.3018V152.207H72.1407V184.109C72.1407 190.384 72.7437 195.318 73.9261 198.79C75.1436 202.349 77.2041 204.883 80.0607 206.335C82.8646 207.757 86.646 208.477 91.3055 208.477C92.4411 208.477 93.8576 208.39 95.5259 208.214C97.1766 208.038 98.8625 207.839 100.542 207.629C102.246 207.412 103.768 207.166 105.067 206.909L105.571 206.809L104.851 195.828V195.816Z" />
        <path d="M170.272 198.081C168.457 197.835 166.941 197.32 165.77 196.548C164.658 195.822 164.043 194.71 163.891 193.205V161.239C163.891 156.234 163.083 152.078 161.491 148.893C159.876 145.668 157.335 143.256 153.928 141.734C150.574 140.236 146.248 139.475 141.086 139.475C138.317 139.475 135.238 139.65 131.93 139.996C128.647 140.341 125.468 140.739 122.489 141.167C119.474 141.606 116.857 142.091 114.703 142.607L114.229 142.724L114.814 152.663L115.429 152.593C117.753 152.335 120.416 152.072 123.343 151.814C126.223 151.563 129.232 151.34 132.293 151.165C135.308 150.995 137.966 150.907 140.19 150.907C143.661 150.907 146.278 151.709 147.975 153.284C149.661 154.846 150.516 157.615 150.516 161.496V166.033L132.223 167.801C127.581 168.064 123.642 168.913 120.516 170.318C117.337 171.752 114.908 173.906 113.298 176.733C111.695 179.537 110.881 183.242 110.881 187.744C110.881 194.452 112.555 199.615 115.851 203.092C119.158 206.575 124.022 208.343 130.309 208.343C133.189 208.343 136.11 208.079 138.99 207.552C141.852 207.031 144.586 206.37 147.115 205.586C149.462 204.854 151.423 204.157 152.939 203.502C153.835 204.286 154.795 204.989 155.801 205.591C156.931 206.271 158.231 206.797 159.653 207.149C160.607 207.406 161.632 207.629 162.703 207.81C163.757 207.986 164.916 208.097 166.157 208.144C167.392 208.185 168.621 208.208 169.815 208.208H170.377L170.816 198.146L170.289 198.075L170.272 198.081ZM150.504 175.656V194.077C149.286 194.47 147.729 194.926 145.868 195.43C143.825 195.986 141.63 196.483 139.353 196.916C137.111 197.338 134.898 197.554 132.768 197.554C129.893 197.554 127.815 196.694 126.405 194.914C124.982 193.123 124.256 190.583 124.256 187.352C124.256 184.12 124.994 181.797 126.451 180.134C127.897 178.483 130.391 177.523 133.868 177.278L150.51 175.65L150.504 175.656Z" />
        <path d="M208.824 171.646L228.972 140.768H214.186L197.286 166.507L188.84 166.858V113.087H175.581V207.043H188.84V178.688L197.55 178.337L214.912 206.762L215.087 207.043H229.827L208.824 171.646Z" />
        <path d="M284.352 170.458C284.352 159.699 282.005 151.756 277.369 146.862C272.727 141.957 265.784 139.469 256.734 139.469C250.857 139.469 245.73 140.675 241.503 143.051C237.254 145.44 233.911 149.25 231.576 154.366C229.258 159.447 228.081 166.173 228.081 174.362C228.081 181.849 228.965 188.165 230.715 193.141C232.483 198.181 235.381 202.021 239.332 204.55C243.265 207.067 248.68 208.343 255.435 208.343C258.186 208.343 261.236 208.231 264.508 208.015C267.763 207.798 270.941 207.488 273.968 207.102C277.017 206.71 279.646 206.335 281.782 205.995L282.291 205.913L281.999 195.687L281.384 195.74C279.347 195.91 276.812 196.085 273.862 196.261C271.017 196.431 268.026 196.583 264.971 196.717C261.997 196.846 259.234 196.911 256.746 196.911C251.08 196.911 247.135 195.4 245.01 192.415C242.908 189.465 241.767 185.039 241.62 179.238H283.667L284.364 170.464L284.352 170.458ZM248.446 152.259C250.688 151.012 253.474 150.386 256.74 150.386C261.728 150.386 265.398 151.797 267.657 154.589C269.882 157.34 271.035 162.216 271.099 169.1H241.609C241.656 164.616 242.253 160.975 243.382 158.283C244.536 155.52 246.245 153.494 248.452 152.265L248.446 152.259Z" />
      </g>
    </svg>
  );
};

const Logo: React.FC<LogoAttributes> = ({ className = "" }: LogoAttributes) => {
  return (
    <svg className={`fill-current ${className}`} viewBox="0 0 713 338">
      <g>
        <title>Sunrise Stake</title>
        <path d="M265.813 71.7712C245.044 51.0026 219.44 36.8368 191.548 30.2983C191.969 32.5695 192.321 34.8349 192.584 37.0885C254.72 53.3967 300.554 109.937 300.554 177.189C300.554 216.162 285.159 251.536 260.123 277.567C256.529 281.483 252.712 285.188 248.709 288.683C207.359 319.11 166.079 300.501 162.374 266.205L162.221 249.417C170.604 249.095 187.011 246.595 200.457 233.15C219.891 213.716 230.931 175.093 230.931 175.093C230.931 175.093 192.309 186.133 172.875 205.567C167.876 210.566 164.393 215.975 161.97 221.226L161.174 132.666C168.66 124.565 181.503 107.045 181.503 82.1497C181.503 46.0855 155.911 0 155.911 0C155.911 0 130.319 46.0855 130.319 82.1497C130.319 106.957 143.068 124.442 150.573 132.579L150.145 172.453C147.5 165.119 143.133 157.187 135.962 150.017C114.368 128.423 71.4551 116.153 71.4551 116.153C71.4551 116.153 83.7243 159.066 105.318 180.66C121.457 196.799 141.429 198.748 149.864 198.783L149.156 265.005C146.288 300.057 104.54 319.45 62.7098 288.677C58.706 285.182 54.8894 281.477 51.2953 277.561C26.2593 251.524 10.8643 216.151 10.8643 177.183C10.8643 109.785 56.8972 53.145 119.238 36.9715C119.508 34.7295 119.859 32.4642 120.292 30.1871C92.2296 36.6846 66.4796 50.8914 45.6056 71.7595C16.197 101.168 0 140.27 0 181.86C0 223.45 16.197 262.552 45.6056 291.961C75.0141 321.37 114.116 337.567 155.706 337.567C197.296 337.567 236.398 321.37 265.807 291.961C295.216 262.552 311.413 223.45 311.413 181.86C311.413 140.27 295.216 101.168 265.807 71.7595L265.813 71.7712Z" />
        <path d="M392.439 127.837C391.409 125.069 389.758 122.71 387.522 120.836C385.315 118.987 382.482 117.418 379.093 116.189C375.744 114.971 371.67 113.83 367.011 112.794C362.117 111.594 358.107 110.458 355.105 109.428C352.207 108.433 350.117 107.063 348.894 105.354C347.676 103.644 347.056 101.092 347.056 97.7673C347.056 94.8698 347.7 92.4932 348.964 90.6961C350.228 88.899 352.148 87.5293 354.66 86.622C357.229 85.6971 360.507 85.2288 364.4 85.2288C366.279 85.2288 368.802 85.3576 371.887 85.6152C375.007 85.8727 378.226 86.1596 381.563 86.4581C384.87 86.7625 387.809 87.0844 390.308 87.4298L390.917 87.5117L391.93 76.103L391.403 76.0094C388.997 75.5821 386.076 75.143 382.734 74.704C379.42 74.2767 376.002 73.9021 372.572 73.5977C369.141 73.2933 366.039 73.1411 363.358 73.1411C357.341 73.1411 352.002 73.984 347.495 75.6465C342.935 77.3323 339.382 80.0718 336.941 83.8005C334.506 87.5176 333.271 92.5224 333.271 98.6746C333.271 102.971 333.832 106.647 334.933 109.597C336.045 112.583 337.836 115.094 340.242 117.061C342.625 119.004 345.657 120.632 349.251 121.902C352.816 123.16 357.036 124.284 361.813 125.238C366.431 126.093 370.154 127.071 372.882 128.13C375.528 129.166 377.424 130.53 378.519 132.192C379.619 133.867 380.176 136.185 380.176 139.082C380.176 142.688 379.543 145.656 378.302 147.892C377.073 150.11 375.194 151.796 372.724 152.903C370.213 154.032 366.97 154.606 363.089 154.606C360.771 154.606 357.944 154.477 354.689 154.22C351.417 153.962 348.033 153.634 344.638 153.248C341.214 152.856 338.217 152.528 335.729 152.27L335.144 152.212L333.985 163.498L334.547 163.574C337.169 163.925 340.225 164.358 343.637 164.873C347.079 165.388 350.65 165.827 354.256 166.179C357.867 166.524 361.099 166.699 363.862 166.699C369.973 166.699 375.34 165.722 379.819 163.79C384.332 161.847 387.856 158.744 390.291 154.583C392.714 150.438 393.943 144.918 393.943 138.175C393.943 134.06 393.428 130.577 392.41 127.837H392.439Z" />
        <path d="M444.776 99.1252V150.801C443.032 151.609 440.79 152.463 438.097 153.347C435.27 154.278 432.121 154.746 428.731 154.746C425.342 154.746 422.761 154.091 421.098 152.791C419.453 151.509 418.365 149.314 417.873 146.27C417.358 143.115 417.1 138.819 417.1 133.498V99.1252H403.842V133.626C403.842 141.178 404.415 147.429 405.545 152.212C406.698 157.088 408.847 160.764 411.932 163.135C415.011 165.499 419.483 166.699 425.219 166.699C429.592 166.699 433.438 166.12 436.657 164.978C439.502 163.966 442.23 162.76 444.776 161.39V165.406H457.9V99.1311H444.776V99.1252Z" />
        <path d="M522.998 115.597C522.295 111.465 521.084 108.046 519.404 105.435C517.694 102.79 515.312 100.834 512.309 99.6227C509.347 98.4286 505.536 97.8257 500.976 97.8257C498.682 97.8257 496.27 98.1593 493.806 98.8208C491.377 99.4706 489.035 100.243 486.852 101.115C485.101 101.818 483.585 102.526 482.327 103.234V99.1252H469.068V165.4H482.327V113.847C483.462 113.297 484.885 112.706 486.559 112.079C488.385 111.4 490.364 110.844 492.448 110.417C494.526 109.995 496.563 109.779 498.5 109.779C501.989 109.779 504.617 110.458 506.327 111.798C508.024 113.127 509.172 115.351 509.751 118.401C510.348 121.568 510.652 125.859 510.652 131.156V165.4H524.04V130.506C524.04 124.706 523.688 119.689 522.992 115.591L522.998 115.597Z" />
        <path d="M569.488 97.703C566.602 98.2298 563.658 99.0903 560.731 100.273C557.822 101.443 555.111 102.702 552.682 104.001C550.704 105.061 548.989 106.08 547.566 107.034V99.1196H534.313V165.394H547.566V118.893C549.065 118.079 550.973 117.16 553.262 116.159C555.697 115.088 558.389 114.07 561.264 113.127C564.132 112.185 566.971 111.447 569.699 110.938L570.179 110.85V97.5801L569.488 97.703Z" />
        <path d="M590.321 99.1252H577.062V165.4H590.321V99.1252Z" />
        <path d="M583.695 73.3928H583.689C580.029 73.3928 577.062 76.3595 577.062 80.0191V80.5401C577.062 84.1997 580.029 87.1664 583.689 87.1664H583.695C587.354 87.1664 590.321 84.1997 590.321 80.5401V80.0191C590.321 76.3595 587.354 73.3928 583.695 73.3928Z" />
        <path d="M647.154 135.143C645.62 132.62 643.162 130.659 639.854 129.318C636.623 128.007 632.251 126.906 626.859 126.034C622.58 125.349 619.238 124.635 616.937 123.909C614.731 123.219 613.256 122.387 612.553 121.451C611.857 120.52 611.5 119.238 611.5 117.646C611.5 115.463 611.997 113.777 612.98 112.635C613.976 111.482 615.404 110.669 617.224 110.224C619.127 109.761 621.287 109.527 623.646 109.527C625.695 109.527 628.13 109.615 630.887 109.785C633.65 109.96 636.442 110.2 639.175 110.499C641.921 110.803 644.362 111.125 646.422 111.47L647.078 111.582L647.517 100.53L647.013 100.442C645.017 100.097 642.606 99.7047 639.854 99.2715C637.068 98.8384 634.147 98.4871 631.174 98.2296C628.182 97.972 625.349 97.8374 622.738 97.8374C618.623 97.8374 614.649 98.4579 610.926 99.6871C607.15 100.928 604.042 103.03 601.689 105.915C599.318 108.825 598.112 112.905 598.112 118.044C598.112 122.469 598.932 125.987 600.547 128.499C602.169 131.022 604.674 132.918 607.987 134.124C611.213 135.301 615.316 136.296 620.186 137.074C624.641 137.759 628.065 138.479 630.366 139.199C632.584 139.896 634.106 140.75 634.891 141.734C635.669 142.711 636.061 144.14 636.061 145.983C636.061 148.348 635.534 150.216 634.498 151.533C633.451 152.856 631.894 153.816 629.862 154.383C627.761 154.975 625.056 155.273 621.831 155.273C620.286 155.273 618.184 155.162 615.574 154.951C612.963 154.74 610.241 154.454 607.478 154.108C604.703 153.763 602.233 153.458 600.067 153.201L599.447 153.125L598.867 163.995L599.359 164.1C601.425 164.534 603.907 164.949 606.741 165.341C609.544 165.728 612.442 166.061 615.345 166.319C618.249 166.576 620.871 166.711 623.131 166.711C628.627 166.711 633.369 166.044 637.226 164.733C641.142 163.398 644.198 161.133 646.293 158.007C648.383 154.887 649.449 150.666 649.449 145.462C649.449 141.131 648.676 137.665 647.154 135.154V135.143Z" />
        <path d="M712.99 128.821C712.99 118.062 710.642 110.118 706.006 105.225C701.364 100.319 694.422 97.8315 685.372 97.8315C679.495 97.8315 674.367 99.0374 670.141 101.414C665.891 103.802 662.549 107.613 660.213 112.729C657.895 117.804 656.719 124.536 656.719 132.725C656.719 140.212 657.603 146.528 659.353 151.503C661.121 156.543 664.018 160.383 667.969 162.912C671.903 165.429 677.318 166.705 684.073 166.705C686.824 166.705 689.874 166.594 693.146 166.377C696.395 166.161 699.579 165.851 702.605 165.464C705.661 165.072 708.289 164.697 710.42 164.358L710.929 164.276L710.636 154.05L710.022 154.102C707.985 154.272 705.456 154.448 702.5 154.623C699.655 154.793 696.664 154.945 693.608 155.08C690.635 155.209 687.866 155.273 685.384 155.273C679.718 155.273 675.772 153.763 673.647 150.778C671.546 147.827 670.405 143.402 670.258 137.601H712.305L713.001 128.826L712.99 128.821ZM677.083 110.622C679.325 109.375 682.112 108.749 685.378 108.749C690.365 108.749 694.036 110.159 696.295 112.951C698.519 115.703 699.673 120.579 699.737 127.463H670.246C670.293 122.979 670.89 119.344 672.02 116.645C673.173 113.882 674.882 111.857 677.089 110.628L677.083 110.622Z" />
        <path d="M392.439 242.475C391.409 239.706 389.758 237.347 387.522 235.474C385.315 233.624 382.482 232.055 379.093 230.826C375.744 229.609 371.67 228.467 367.011 227.431C362.117 226.231 358.107 225.095 355.105 224.065C352.207 223.07 350.117 221.7 348.894 219.991C347.676 218.282 347.056 215.73 347.056 212.405C347.056 209.507 347.7 207.131 348.964 205.334C350.228 203.537 352.148 202.167 354.66 201.259C357.229 200.335 360.507 199.866 364.4 199.866C366.279 199.866 368.802 199.995 371.887 200.253C375.007 200.51 378.226 200.797 381.563 201.096C384.87 201.4 387.809 201.722 390.308 202.067L390.917 202.149L391.93 190.74L391.403 190.647C388.997 190.22 386.076 189.78 382.734 189.341C379.42 188.914 376.002 188.54 372.572 188.235C369.141 187.931 366.039 187.779 363.358 187.779C357.341 187.779 352.002 188.621 347.495 190.284C342.935 191.97 339.382 194.709 336.941 198.438C334.506 202.155 333.271 207.16 333.271 213.312C333.271 217.609 333.832 221.285 334.933 224.235C336.045 227.22 337.836 229.731 340.242 231.698C342.625 233.642 345.657 235.269 349.251 236.539C352.816 237.798 357.036 238.922 361.813 239.876C366.431 240.73 370.154 241.708 372.882 242.767C375.528 243.804 377.424 245.167 378.519 246.83C379.619 248.504 380.176 250.822 380.176 253.72C380.176 257.325 379.543 260.293 378.302 262.529C377.073 264.748 375.194 266.434 372.724 267.54C370.213 268.67 366.97 269.243 363.089 269.243C360.771 269.243 357.944 269.115 354.689 268.857C351.417 268.599 348.033 268.272 344.638 267.885C341.214 267.493 338.217 267.165 335.729 266.908L335.144 266.849L333.985 278.135L334.547 278.211C337.169 278.562 340.225 278.996 343.637 279.511C347.079 280.026 350.65 280.465 354.256 280.816C357.867 281.161 361.099 281.337 363.862 281.337C369.973 281.337 375.34 280.359 379.819 278.428C384.332 276.484 387.856 273.382 390.291 269.22C392.714 265.076 393.943 259.556 393.943 252.812C393.943 248.697 393.428 245.214 392.41 242.475H392.439Z" />
        <path d="M437.851 268.816L437.26 268.869C436.218 268.956 435 269.062 433.613 269.196C432.237 269.325 430.891 269.413 429.621 269.454C428.374 269.495 427.291 269.518 426.389 269.518C423.937 269.518 422.134 269.079 421.033 268.213C419.945 267.358 419.207 265.801 418.844 263.577C418.458 261.236 418.265 257.922 418.265 253.731V225.207H437.628V213.769H418.265V194.668H405.141V213.769H396.302V225.207H405.141V257.109C405.141 263.384 405.744 268.318 406.926 271.79C408.144 275.349 410.204 277.883 413.061 279.335C415.865 280.757 419.646 281.477 424.305 281.477C425.441 281.477 426.858 281.39 428.526 281.214C430.177 281.038 431.862 280.839 433.542 280.629C435.246 280.412 436.768 280.166 438.067 279.909L438.571 279.809L437.851 268.828V268.816Z" />
        <path d="M503.272 271.081C501.457 270.835 499.941 270.32 498.77 269.548C497.658 268.822 497.043 267.71 496.891 266.205V234.239C496.891 229.234 496.083 225.078 494.491 221.893C492.876 218.668 490.335 216.256 486.928 214.734C483.574 213.236 479.248 212.475 474.086 212.475C471.317 212.475 468.238 212.65 464.93 212.996C461.647 213.341 458.468 213.739 455.489 214.167C452.474 214.606 449.857 215.091 447.703 215.607L447.229 215.724L447.814 225.663L448.429 225.593C450.753 225.335 453.416 225.072 456.343 224.814C459.223 224.563 462.232 224.34 465.293 224.165C468.308 223.995 470.966 223.907 473.19 223.907C476.661 223.907 479.278 224.709 480.975 226.284C482.661 227.846 483.516 230.615 483.516 234.496V239.033L465.223 240.801C460.581 241.064 456.642 241.913 453.516 243.318C450.337 244.752 447.908 246.906 446.298 249.733C444.695 252.537 443.881 256.242 443.881 260.744C443.881 267.452 445.555 272.615 448.851 276.092C452.158 279.575 457.022 281.343 463.309 281.343C466.189 281.343 469.11 281.079 471.99 280.552C474.852 280.031 477.586 279.37 480.115 278.586C482.462 277.854 484.423 277.157 485.939 276.502C486.835 277.286 487.795 277.989 488.801 278.591C489.931 279.271 491.231 279.797 492.653 280.149C493.607 280.406 494.632 280.629 495.703 280.81C496.757 280.986 497.916 281.097 499.157 281.144C500.392 281.185 501.621 281.208 502.815 281.208H503.377L503.816 271.146L503.289 271.075L503.272 271.081ZM483.504 248.656V267.077C482.286 267.47 480.729 267.926 478.868 268.43C476.825 268.986 474.63 269.483 472.353 269.916C470.111 270.338 467.898 270.554 465.768 270.554C462.893 270.554 460.815 269.694 459.405 267.914C457.982 266.123 457.256 263.583 457.256 260.352C457.256 257.12 457.994 254.797 459.451 253.134C460.897 251.483 463.391 250.523 466.868 250.278L483.51 248.65L483.504 248.656Z" />
        <path d="M541.824 244.646L561.972 213.768H547.186L530.286 239.507L521.84 239.858V186.087H508.581V280.043H521.84V251.688L530.55 251.337L547.912 279.762L548.087 280.043H562.827L541.824 244.646Z" />
        <path d="M617.352 243.458C617.352 232.699 615.005 224.756 610.369 219.862C605.727 214.957 598.784 212.469 589.734 212.469C583.857 212.469 578.73 213.675 574.503 216.051C570.254 218.44 566.911 222.25 564.576 227.366C562.258 232.447 561.081 239.173 561.081 247.362C561.081 254.849 561.965 261.165 563.715 266.141C565.483 271.181 568.381 275.021 572.332 277.55C576.265 280.067 581.68 281.343 588.435 281.343C591.186 281.343 594.236 281.231 597.508 281.015C600.763 280.798 603.941 280.488 606.968 280.102C610.017 279.71 612.646 279.335 614.782 278.995L615.291 278.913L614.999 268.687L614.384 268.74C612.347 268.91 609.812 269.085 606.862 269.261C604.017 269.431 601.026 269.583 597.971 269.717C594.997 269.846 592.234 269.911 589.746 269.911C584.08 269.911 580.135 268.4 578.01 265.415C575.908 262.465 574.767 258.039 574.62 252.238H616.667L617.364 243.464L617.352 243.458ZM581.446 225.259C583.688 224.012 586.474 223.386 589.74 223.386C594.728 223.386 598.398 224.797 600.657 227.589C602.882 230.34 604.035 235.216 604.099 242.1H574.609C574.656 237.616 575.253 233.975 576.382 231.283C577.536 228.52 579.245 226.494 581.452 225.265L581.446 225.259Z" />
      </g>
    </svg>
  );
};

export { Logo, LogoIcon, LogoText };
